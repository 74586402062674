<template>
  <div id="app">
    <main>
      <router-view/>
    </main>

    <!-- <Footer/> -->

  </div>
</template>

<script>
// import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  // components: { Footer }
}
</script>

<style lang="less">

</style>
